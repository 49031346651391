<template>
	<v-container class="text-center fill-height justify-center">
		<div>
			<h1 class="my-4">¡Oops! 404</h1>
			<p>La página que estabas buscando no existe</p>
			<v-btn
				:to="{
					name: 'Dashboard',
				}"
				color="primary"
				class="my-1 text-none"
				rounded
				link
			>
				Ir a la página de Inicio
			</v-btn>
		</div>
	</v-container>
</template>

<script>
export default {
	name: 'PageNotFound',
	components: {},
};
</script>
